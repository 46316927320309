.alert {
  @apply border border-gray-500 bg-gray-100 text-gray-700 p-4 rounded-lg;

  &.alert-info {
    @apply border-primary-500 bg-primary-200 text-primary-500;
  }

  &.alert-success {
    @apply border-secondary-500 bg-secondary-200 text-secondary-500;
  }

  &.alert-error {
    @apply border-red-500 bg-red-100 text-red-500;
  }

  &.alert-warning {
    @apply border-orange-500 bg-orange-100 text-orange-500;
  }
}
