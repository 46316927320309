.form-label {
  @apply text-sm text-secondary-500;

  &.error {
    @apply text-red-500;
  }
}

.form-input {
  @apply block w-full rounded-md border border-gray-300 focus:border-indigo-500 text-sm text-gray-700 text-secondary-500 placeholder-opacity-30 bg-white mt-1;

  &.with-bg-color {
    @apply bg-input rounded-sm;
  }

  &.error {
    @apply border-red-500;
  }
}

.form-image {
  @apply block text-sm text-secondary-500;
}

.form-info {
  @apply px-3 py-2 mt-1 text-sm text-gray-700 bg-input rounded-sm truncate;
}

.form-layout {
  @apply px-6 py-2 xl:px-10 xl:py-3;
}

.form-checkbox {
  @apply rounded border border-gray-300 focus:border-indigo-500 bg-white;
}

.form-field {
  @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-300 focus:outline-none sm:text-sm;
}

.form-actions {
  @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.form-hint {
  @apply text-gray-500 text-xs;

  &.error {
    @apply text-red-500 text-xs leading-normal mt-1;
  }
}
