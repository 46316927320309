.sidebar {
  @apply text-primary text-secondary-500 w-full flex items-center justify-between py-3 hover:text-indigo-500;

  &.dropdown-btn {
    @apply justify-between px-6 xl:px-8 border-l-4 border-white focus:outline-none;
  }

  &.dropdown-menu {
    @apply px-6 border-l-4 border-white hover:bg-indigo-50 hover:border-indigo-500;
  }

  &.active {
    @apply text-indigo-500 bg-indigo-50 border-indigo-500;
  }
}

.sidebar-controller {
  @apply space-y-2 relative;
}

.sidebar-target {
  @apply space-y-2 bg-white rounded-r-lg shadow-md absolute top-0 -right-44 w-44 overflow-hidden;
}
