.switch {
  @apply relative inline-block w-12 h-6;
}

.switch input {
  @apply opacity-0 w-0 h-0;
}

.slider {
  @apply absolute cursor-pointer top-0 right-0 left-0 bottom-0 bg-gray-300 rounded-full transition duration-300;
}

.slider::before {
  content: "";
  @apply absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition duration-300;
}

input:checked + .slider {
  @apply bg-indigo-500;
}

input:checked + .slider::before {
  @apply transform translate-x-6;
}
