.margin-x-layout {
  @apply mx-4 sm:mx-10;
}

.add-btn-layout {
  @apply my-8 sm:text-right;
}

.pagy-btn-layout {
  @apply text-right py-4;
}

.import-layout {
  @apply mt-4 sm:mt-10 p-6 bg-white border border-gray-300 rounded-lg text-gray-500;

  &.dashed {
    @apply bg-transparent border-dashed text-sm text-gray-300;
  }
}
