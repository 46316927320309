.table-title-outer {
  @apply border border-gray-100 rounded-lg rounded-b-none shadow overflow-hidden;
}

.table-title-ul {
  @apply bg-gray-100 border-b border-gray-100 text-xs text-secondary-500 opacity-70 truncate hidden sm:block;
}

.table-list-ul {
  @apply bg-white divide-y divide-gray-100;
}

.table-list-grid {
  @apply min-w-0 flex-1 px-4 py-3 sm:px-6 grid grid-cols-1 gap-1 sm:gap-4 sm:h-auto overflow-hidden;
}

.table-list-grid-cols {
  @apply flex items-center;

  &.first-column {
    @apply justify-between;
  }

  &.last-column {
    @apply items-stretch justify-end;
  }

  &.gap {
    @apply mb-3 sm:mb-0;
  }
}

.table-list-grid-rows {
  @apply text-sm text-gray-700 truncate;

  &.first-row {
    @apply text-base font-bold sm:text-sm sm:font-normal;
  }

  &.rows-title {
    @apply w-1/3 sm:hidden;
  }

  &.rows-content {
    @apply w-2/3 sm:w-auto;
  }

  &.rows-title-sm {
    @apply w-1/6 sm:hidden;
  }

  &.rows-content-sm {
    @apply w-5/6 sm:w-auto;
  }

}

.table-list-li {
  @apply text-sm text-gray-700 truncate;

  &.subtitle {
    @apply text-gray-300;
  }
}

.no-content-outer {
  @apply text-center bg-white;
}

.no-content-list {
  @apply text-sm text-secondary-500 p-4;
}