$btn-color: (
  primary: 'indigo',
  secondary: 'gray',
  edit: 'yellow',
  delete: 'red'
);

@each $key, $value in $btn-color {
  .#{$key}-btn {
    @apply px-4 py-2 inline-flex items-center border border-transparent rounded-lg shadow text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer;

    &.outline {
      @apply bg-white hover:text-white;
    }

    &.small {
      @apply p-1;
    }
  }

  .#{$key}-tab {
    @apply inline-block pb-1 xl:mr-3 text-sm text-#{$value}-400 cursor-pointer;

    &.active {
      @apply text-#{$value}-900 border-b-2 border-#{$value}-900;
    }
  }

  .#{$key}-subtab {
    @apply inline-block text-sm text-gray-500 px-3 py-1 rounded-lg mr-2 cursor-pointer hover:bg-#{$value}-500 hover:text-white;

    &.active {
      @apply bg-#{$value}-500 text-white;
    }
  }
}

$colors: 'gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink';

@each $color in $colors {
  .radio-#{$color}-btn {
    @apply block w-8 h-4 rounded-full border border-#{$color}-400 bg-#{$color}-400 text-#{$color}-400 ml-2 focus:ring-#{$color}-400;
  }
}

.action-btn {
  @apply text-secondary-500 border rounded hover:text-white hover:bg-secondary-500 p-1 ml-2 flex items-center cursor-pointer;

  &.large {
    @apply p-2;
  }
}

.copy-btn {
  @apply text-gray-300 hover:text-gray-500 cursor-pointer;
}

.switch-btn {
  @apply w-32 border border-gray-500 rounded flex justify-between text-sm;
}

.switch-btn-active {
  @apply inline-block w-16 py-0.5 text-center bg-gray-100 text-white;
}

.switch-btn-inactive {
  @apply inline-block w-16 py-0.5 text-center text-gray-500;
}

.condition-btn {
  @apply p-2 border border-gray-300 rounded-lg text-gray-500;
}

.condition-action-btn {
  @apply flex items-center text-sm text-gray-300 ml-1 focus:outline-none;

  &.submit {
    @apply bg-white cursor-pointer;
  }
}

.download-btn {
  @apply block w-1/2 py-8 border border-dashed border-gray-300 rounded-lg bg-gray-100 text-gray-500 text-sm flex items-center justify-center shadow;
}

.radio_bar_btn {
  @apply block w-1/3 py-1 text-center;

  &.active {
    @apply text-white;
  }
}

.bookmark_btn {
  @apply block w-1/2 text-center py-2 border-gray-100;

  &.active_left {
    @apply border-t border-r rounded-tr-lg text-indigo-500;
  }
  &.active_right {
    @apply border-t border-l rounded-tl-lg text-indigo-500;
  }
  &.inactive {
    @apply border-b;
  }
}

.no-decoration-btn {
  @apply rounded-lg border border-secondary-500 text-xs px-1 hover:bg-secondary-500 hover:text-white;
}
