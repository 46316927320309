@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '../stylesheets/components/analysis';
@import '../stylesheets/components/button';
@import '../stylesheets/components/form';
@import '../stylesheets/components/image';
@import '../stylesheets/components/layout';
@import '../stylesheets/components/navigation';
@import '../stylesheets/components/sidebar';
@import '../stylesheets/components/table';
@import '../stylesheets/components/tag';
@import '../stylesheets/components/pagy';
@import '../stylesheets/components/toggle';
@import '../stylesheets/components/alert';
@import '../stylesheets/components/icons';

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}