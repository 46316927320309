body {
  touch-action: pan-y;
}

.dialog {
  position: relative;
  overflow: visible;
}

.dialog::after {
  content: '';
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #9CA3AF transparent transparent;
  position: absolute;
  top: 4px;
  left: -20px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  min-width: 300px;
  background-color: #666;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 50%;
  /* left: 50%; */
  right: 0px;
  /* margin-left: -400px; */

}

.tooltip:hover .tooltip-text {
  visibility: visible;
}
