.pagy-nav,
.pagy-nav-js,
.pagy-combo-nav-js {
  @apply inline-flex bg-white rounded-lg border border-gray-100;
}
.pagy-nav.pagination,
.pagy-nav-js.pagination,
.pagy-combo-nav-js.pagination {
  @apply rounded-lg;
}
.pagy-nav .page,
.pagy-nav-js .page,
.pagy-combo-nav-js .page,
.pagy-combo-nav-js .pagy-combo-input {
  @apply text-secondary-500 px-3 py-2 text-sm leading-tight font-medium border-r border-gray-100;
}
.pagy-nav .page:hover,
.pagy-nav-js .page:hover {
  @apply text-white bg-indigo-500;
}
.pagy-nav .disabled,
.pagy-nav-js .disabled,
.pagy-combo-nav-js .disabled {
  @apply cursor-not-allowed;
}
.pagy-nav .active,
.pagy-nav-js .active {
  @apply text-indigo-500;
}
.pagy-nav .prev,
.pagy-nav-js .prev,
.pagy-combo-nav-js .prev {
  @apply text-secondary-500;
}
.pagy-nav .next,
 .pagy-nav-js .next,
 .pagy-combo-nav-js .next {
  @apply text-secondary-500 border-r border-transparent;
}

.prev {
  @apply rounded-lg rounded-r-none;
}

.next {
  @apply rounded-lg rounded-l-none;
}
