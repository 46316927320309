$nav-color: (
  active: "gray-500",
  inactive: "gray-300"
);

@each $key, $value in $nav-color {
  .navigation-#{$key} {
    @apply w-48 text-sm text-#{$value};

    &.title {
      @apply pl-18 -mb-5;
    }

    &.info {
      @apply py-3 flex items-center;
    }

    &.number {
      @apply w-8 h-8 rounded-full border-2 border-#{$value} flex items-center justify-center;
    }

    &.bar {
      @apply block w-40 h-0.5 bg-#{$value};
    }

    &.check-with-bg {
      @apply text-white bg-#{$value};
    }
  }
}
